<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <v-toolbar elevation="0" color="grey lighten-5">
        <v-toolbar-title class="ml-3 py-2 subtitle">
          Team Invites
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="actions">
        </div>
      </v-toolbar>
      <v-divider/>

      <div >
        <div v-if="groupParticipants && groupParticipants.length > 0">

          <v-card-title class="subtitle pt-4">
            {{groupParticipants.length}} teams
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table v-if="groupParticipants"
            :headers="groupHeaders"
            :items="groupParticipants"
            :items-per-page="50"
            :search="search"
            group-by="category"
            >
            <template v-slot:group.header="{items, isOpen, toggle}">
              <th colspan="9" v-if="items[0].category">
                <v-icon small class="me-2" @click="toggle">{{ isOpen ? 'fa-minus' : 'fa-plus' }}</v-icon>
                {{ items[0].category }}
              </th>
            </template>
            <template v-slot:item.link="{ item }">
              <span class="link-url">{{ item.status && item.status.join_url }}</span>
            </template>
            <template v-slot:item.qr="{ item }">
              <span class="qr-code"><QrCode v-if="item.status && item.status.join_url" :data="item.status.join_url" /></span>
            </template>

          </v-data-table>
        </div>
        <v-card-text v-else-if="!groupParticipants">
          Loading invites...
        </v-card-text>
        <v-card-text v-else>
          No teams have been created yet for this event.
        </v-card-text>
      </div>      
    </v-card>

  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import Header from './_Header.vue'
import QrCode from '@/components/generic/QrCode.vue'

export default {
  name: "Participants",
  components: {
    Header,
    QrCode,
  },
  props: {
  },
  data() {
    return {
      event: null,
      groupParticipants: null,
      joinInfo: null,
      search: null,
      groupHeaders: [
        { text: 'Name', align: 'start', sortable: true, groupable: false, value: 'name',},
        { text: 'Join Link', align: 'start', sortable: true, groupable: false, value: 'link', class: 'link',},
        { text: 'Join QR', align: 'start', sortable: true, groupable: false, value: 'qr', class: 'qr',},
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      if (this.event.verification_method === 'ALLOWLIST') {
        this.joinInfo = (await eventManagerService.getJoinInfo(id)).data;
      }

      var teams = (await eventManagerService.getGroupParticipants(id)).data.data;

      //var newList = [];
      for (var team of teams) {
        team.status = (await profileService.getGroupEventStatus(team.group_id, this.event.id)).data;
        //newList.push(team);
      }
      this.groupParticipants = teams;
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },


  },
  computed: {
    showTeamCategoryOption() {
      return this.event && this.event.races && this.event.races.some(x => !!x.team_category);
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Teams', exact: true, to: { name: 'eventmanagerTeams', params: {id: this.event.id}} },
        { text: 'Invites', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
.link-url { max-width: 200px; display: inline-block; }
.qr-code { min-width: 400px; }
</style>

