var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems}}),(_vm.event)?_c('v-card',[_c('v-toolbar',{attrs:{"elevation":"0","color":"grey lighten-5"}},[_c('v-toolbar-title',{staticClass:"ml-3 py-2 subtitle"},[_vm._v(" Team Invites ")]),_c('v-spacer'),_c('div',{staticClass:"actions"})],1),_c('v-divider'),_c('div',[(_vm.groupParticipants && _vm.groupParticipants.length > 0)?_c('div',[_c('v-card-title',{staticClass:"subtitle pt-4"},[_vm._v(" "+_vm._s(_vm.groupParticipants.length)+" teams "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.groupParticipants)?_c('v-data-table',{attrs:{"headers":_vm.groupHeaders,"items":_vm.groupParticipants,"items-per-page":50,"search":_vm.search,"group-by":"category"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [(items[0].category)?_c('th',{attrs:{"colspan":"9"}},[_c('v-icon',{staticClass:"me-2",attrs:{"small":""},on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'fa-minus' : 'fa-plus'))]),_vm._v(" "+_vm._s(items[0].category)+" ")],1):_vm._e()]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"link-url"},[_vm._v(_vm._s(item.status && item.status.join_url))])]}},{key:"item.qr",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"qr-code"},[(item.status && item.status.join_url)?_c('QrCode',{attrs:{"data":item.status.join_url}}):_vm._e()],1)]}}],null,false,2718775623)}):_vm._e()],1):(!_vm.groupParticipants)?_c('v-card-text',[_vm._v(" Loading invites... ")]):_c('v-card-text',[_vm._v(" No teams have been created yet for this event. ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }